/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { ModelAccountState } from '@/store/modelAccount/types'
import { toBase64 } from '@/services/api.upload'

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountAddVideoPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;
	@Action('uploadModelsVideo', {namespace}) uploadModelsVideo: any;
	@Action('getModelsVideo', {namespace}) getModelsVideo: any;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;

	modelVideo: Array<any> = [];
	videoValue: string = '';

	$refs!: {
		refUploadVideo: HTMLInputElement
	}

	async handleFileUpload() {
		const video = this.$refs.refUploadVideo.files![0];
		this.videoValue = this.$refs.refUploadVideo.value;
		return await toBase64(video).then(data => this.modelVideo.push(data));
	}
	onSubmit() {
		this.uploadModelsVideo([{
			name: this.modelVideo
		}])
		this.modelVideo = [];
	}

	mounted() {
		this.getModelsVideo();
	}
}
